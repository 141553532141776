/*
 * @Description: 
 * @Author: 
 * @Date: 2022-11-22 09:47:55
 * @LastEditors: koushaoping 1719422019@qq.com
 * @LastEditTime: 2024-02-28 10:39:00
 */
import {request} from "./request";

// 获取左侧菜单列表
export function getMenuListRequest() {
  return request({
    url: '/platform/login/checkMenuPath'
  })
}
export function getEnterpriseIdRequest() {
  return request({
    url: '/platform/login/checkTokenInfo'
  })
}
// 修改密码

export function editPassword(params){
  return request({
    url: '/platform/sysuser/updateNewPassword',
    method: 'post',
    params
  })
}
