<template>
  <div class="login-container">
    <img style="width: 100%;height: 100%" src="../assets/images/1504.png" alt="">
    <div class="login-box">
      <div class="left-box">
        <img src="../assets/images/1503.png" alt="">
      </div>
      <div>
        <div style="marginTop: 114px;marginLeft:106px;">
          <span >LNG贸易系统管理平台</span>
        </div>
        <div style="marginTop: 46px;marginLeft:106px;">
          <el-tabs v-model="activeName">
            <el-tab-pane label="手机登录" name="first">
              
                  <el-input v-model="phone" placeholder="请输入手机号"></el-input>
              
                  <div class="verify-wrapper">
                      <el-input type="text" maxlength="6" placeholder="请输入验证码" v-model="verify"/>
                      <el-button type="primary" :disabled='disabled' style="marginLeft: 15px" @click="bindforgetSendCode">{{btnText}}</el-button>
                  </div>
            </el-tab-pane>
            <el-tab-pane label="账户登录" name="second">
              <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
                <el-form-item label="" prop="name">
                  <el-input type="text" v-model="ruleForm.name"  placeholder="请输入账号"></el-input>
                </el-form-item>
                <el-form-item label="" prop="pass">
                  <el-input type="password" v-model="ruleForm.pass"  placeholder="请输入密码"></el-input>
                </el-form-item>
              </el-form>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div style="marginTop: 20px;marginLeft:106px;">
          <el-checkbox v-model="checked" text-color='#616ffe'>自动登录</el-checkbox>
        </div>
        <div class="save-box" @click="login">
          登录
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {loginRequest,sendCodeRequest,codeLoginRequest} from "network/login";
  import {getMenuListRequest,getEnterpriseIdRequest} from "network/home";


  export default {
    name: "Login",
    data() {
      return {
        checked: false,
        activeName: 'first',
        phone: '',
        verify: '',
  // 发送验证码的 文本 以及按钮是否可以按
      type: "",
      btnText: "发送验证码",
      disabled: false,
      ruleForm: {
          name: '',
          pass: '',
          
        },
        rules: {
          name: [
            { required: true, message: '请输入账号', trigger: 'blur' },
          ],
          pass: [
            { required: true, message: '请输入密码', trigger: 'blur' },
          ],
        }
      }
    },

    created() {
      this.getMenuList();
      
    },
    mounted() { // 创建实例后执行
      // 浏览器控制按钮前进后退触发函数
      window.addEventListener('popstate', this.popstate, false);
    },
    // 销毁组件
    destroyed () {
      // 避免堆栈溢出，多次创建、多次触发
      window.removeEventListener('popstate', this.popstate, false);
    },
    methods: {
      popstate(){ this.$router.push({path: '/login',replace:true}) },

    getMenuList() {
      let NewToken = localStorage.getItem('token') 
      let  NewChecked = localStorage.getItem('checked')
      if(NewToken!=''&&NewChecked=='true'){
      getMenuListRequest().then(res => {
        
        let result = res.data;
        if (result.code !== 303) {
          this.$router.push(result.data.index)
        }
        else{
          localStorage.clear()
          return this.alertMessage('token已失效，请重新登录', 'error');
        }
      })
      }
    },

    // 发送验证码
    bindforgetSendCode() {
      //手机号 为空的话
     
        if (this.phone!=='') {
          sendCodeRequest({
            phone: this.phone
          }).then(res=>{
            console.log(res);
          })
          this.disabled = true;
          this.btnText = "请稍候...";
          setTimeout(() => {
            this.doLoop(60);
          }, 500);
        } else {
          return false;
        }
      
    },

         // 手机验证码的倒计时
    doLoop (seconds) {
      
      seconds = seconds ? seconds : 60;
      this.btnText = seconds + "s后获取";
      // this.code = code
      let countdown = setInterval(() => {
        if (seconds > 0) {
          this.btnText = seconds + "s后获取";
          --seconds;
        } else {
          this.btnText = "获取验证码";
          this.disabled = false;
          clearInterval(countdown);
        }
      }, 1000);
    },

    getEnterpriseId(){
        getEnterpriseIdRequest().then(res => {
          let result = res.data;
          console.log(result);
          if(result.code !== 200){
            // this.$message({
            //   message: result.message,
            //   type: 'error'
            // })
          }
          localStorage.setItem('enterpriseId',result.data.enterpriseId)
          localStorage.setItem('userName',result.data.userName)
        })
      },

      login() {
        localStorage.clear()
        if(this.activeName=='first'){
          if(this.phone!==''&&this.verify!==''){
            codeLoginRequest({
            phone:this.phone,
            code:this.verify,
          }).then(res => {
            if (res.data.code !== 200) {
              this.$message({
                message: res.data.message,
                type: 'error'
              });
              return;
            }
            this.$message({
              message: '恭喜，登录成功',
              type: 'success'
            });
            this.getEnterpriseId()
            localStorage.setItem('token', res.data.data.token);
            localStorage.setItem('enterpriseName',res.data.data.enterpriseName);
            localStorage.setItem('type',res.data.data.type)
            localStorage.setItem('checked',this.checked)
            this.getMenuList();
            this.$router.push('/home');
          }).catch(err => {
            console.log(res.data.message , err);
          })
          }else{
            this.$message({
                message: '请输入手机号和验证码',
                type: 'error'
              });
          }
        }
        if(this.activeName== 'second'){
        // 表单登录前的预校验
        this.$refs.ruleForm.validate(valid => {
          if (!valid) return;
          loginRequest({
            username:this.ruleForm.name,
            password:this.ruleForm.pass,
          }).then(res => {
            if (res.data.code !== 200) {
              this.$message({
                message: res.data.message,
                type: 'error'
              });
              return;
            }
            this.$message({
              message: '恭喜，登录成功',
              type: 'success'
            });
            
            localStorage.setItem('token', res.data.data.token);
            localStorage.setItem('enterpriseName',res.data.data.enterpriseName);
            localStorage.setItem('type',res.data.data.type)
            localStorage.setItem('checked',this.checked)
            this.$router.push('/home').catch(() => true);
            this.getEnterpriseId()
            this.getMenuList();
          }).catch(err => {
            console.log('登录失败：', err);
          })
        })
        }
      }
    }
  }
</script>

<style scoped>
  .login-container {
    height: 100%;
  }

  .login-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1224px;
    height: 679px;
    background-color: #fff;
    display: flex;
    justify-items: flex-start;
    /* border-radius: 3px; */
  }
  .left-box{
    width: 626px;
    height: 679px;
    background: #F1F5F8;
  }
  .left-box img{
    margin-left: 37px;
    margin-top: 200px;
  }

  .avatar-box {
    height: 130px;
    width: 130px;
    border: 1px solid #eee;
    border-radius: 50%;
    padding: 5px;
    box-shadow: 0 0 10px #ddd;
    margin: 0 auto;
    transform: translateY(-50%);
    background-color: #fff;
  }

  .avatar-box img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #eee;
  }

  .login-form {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
  }

  .btns {
    display: flex;
    justify-content: flex-end;
  }
  .verify-wrapper{
    display: flex;
    margin-top: 15px;
  }
  .save-box{
    width: 343px;
    height: 50px;
    background: #616ffe;
    border-radius: 2px;
    color: #ffffff;
    line-height: 50px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    margin-left: 106px;
    margin-top: 40px;
    cursor:pointer
  }

  .el-button--primary{
    background-color: #616ffe;
    border: 1px solid #616ffe
  }

</style>
