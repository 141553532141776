/*
 * @Description: 
 * @Author: 
 * @Date: 2022-11-22 09:47:55
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-04-04 11:00:08
 */
import {request} from "./request";

export function loginRequest(loginData) {
  return request({
    url: '/platform/login/authentcation',
    method: 'post',
    params: {
      username: loginData.username,
      password: loginData.password
    }
  })
}

// 获取手机验证码
export function sendCodeRequest(params) {
  return request({
    url: '/platform/login/sendCode',
    params
  })
}

// 手机登录接口
export function codeLoginRequest(params){
  return request ({
    url: '/platform/login/loginByCode',
    params
  })
}

